import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import moment from 'moment';
import {
    RuxAccordionItem, RuxButton, RuxButtonGroup, RuxCheckbox, RuxIndeterminateProgress,
    RuxOption, RuxRadio, RuxRadioGroup, RuxSelect, RuxTable, RuxTableBody,
    RuxTableCell, RuxTableHeaderCell, RuxTableHeaderRow, RuxTableRow
} from "@astrouxds/react";
import Pagination from '../../../components/Pagination';
import { getObjects } from "../../../apis/objectApi";
import { getAccountStations } from '../../../apis/accountApi';
import { createBooking } from "../../../apis/bookingApi";
import { getPredictions } from "../../../apis/predictionAPi";
import { notify } from "../../../libs/notificationSystem/notificationManager";
import { useAuth } from '../../../auth/AuthContext';

const FuturePassesTable = ({ handleTimelineFormSubmit, shouldPredictionRefresh, setShouldPredictionRefresh }) => {
    const [objects, setObjects] = useState([]);
    const [stations, setStations] = useState([]);
    const [predictions, setPredictions] = useState([]);
    const [selectedPredictions, setSelectedPredictions] = useState([]);
    const [selectedObjectIds, setSelectedObjectIds] = useState([]);
    const [selectedStationIds, setSelectedStationIds] = useState([]);
    const [timeOption, setTimeOption] = useState('3h');
    const [isLoading, setIsLoading] = useState(false);
    const [refreshPredictions, setRefreshPredictions] = useState(false);
    const [pageInfo, setPageInfo] = useState({
        page: 0,
        perPage: 20
    })
    const [totalEntries, setTotalEntries] = useState(0)
    const { user } = useAuth()

    objects.sort((a, b) => a.name.localeCompare(b.name));
    stations.sort((a, b) => a.station_name.localeCompare(b.station_name));

    useEffect(() => {
        if (shouldPredictionRefresh) {
            setRefreshPredictions(true);
        }
    }, [shouldPredictionRefresh]);

    useEffect(() => {
        if (refreshPredictions) {
            setPredictions([]);
            setRefreshPredictions(false);
            setShouldPredictionRefresh(false);
        }
    }, [refreshPredictions]);

    useEffect(() => {
        const loadData = async () => {
            try {
                const [objectsResponse, stationsResponse] = await Promise.all([
                    getObjects(),
                    getAccountStations(user)
                ]);

                setObjects(objectsResponse || []);
                const allObjectIds = objectsResponse.map(object => String(object.norad_id));
                setSelectedObjectIds(allObjectIds);

                setStations(stationsResponse || []);
                const allStationIds = stationsResponse.map(station => station.station_id);
                setSelectedStationIds(allStationIds);
            } catch (error) {
                console.error('Error loading data:', error);
            }
        };
        loadData();
    }, []);


    const timeToNow = (time) => {
        const now = new Date();
        const futureTime = new Date(time);
        const diffMs = futureTime - now;

        if (diffMs > 0) {
            if (diffMs < 30 * 60 * 1000) {
                return '-' + moment.utc(diffMs).format('mm:ss');
            } else {
                return moment(futureTime).fromNow();
            }
        } else {
            return moment(futureTime).fromNow();
        }
    };

    const duration = (startTime, endTime) => moment.utc(new Date(endTime) - new Date(startTime)).format('mm:ss');

    const handleFormSubmit = (event) => {
        event.preventDefault();
        setIsLoading(true);

        const nowUTC = moment.utc();

        let time_to;
        switch (timeOption) {
            case '3h':
                time_to = nowUTC.clone().add(3, 'hours');
                break;
            case '12h':
                time_to = nowUTC.clone().add(12, 'hours');
                break;
            case '24h':
                time_to = nowUTC.clone().add(24, 'hours');
                break;
            default:
                time_to = nowUTC;
        }
        const time_from = nowUTC;

        let normalizedSelectedObjectIds = Array.isArray(selectedObjectIds) ? selectedObjectIds : [selectedObjectIds];
        const selectedObjects = normalizedSelectedObjectIds.map(norad_id => ({ norad_id: parseInt(norad_id, 10) }));

        let normalizedSelectedStationIds = Array.isArray(selectedStationIds) ? selectedStationIds : [selectedStationIds];
        const selectedStations = normalizedSelectedStationIds.map(stationId => ({ station_id: stationId }));

        if (selectedObjects.length !== 0) {
            getPredictions(selectedObjects, selectedStations, time_from.toISOString(), time_to.toISOString(), pageInfo.page*pageInfo.perPage, pageInfo.perPage, true)
                .then((fetchedPredictions) => {
                    fetchedPredictions?.data.sort((a, b) => moment(a.time_aos).diff(moment(b.time_aos)));
                    setPredictions(fetchedPredictions?.data);
                    setSelectedPredictions([]);
                    setIsLoading(false);
                    setTotalEntries(fetchedPredictions.entries)
                })
                .catch((error) => {
                    console.error('Error calculating predictions:', error);
                    notify('Error calculating predictions: ' + error.message, 'critical');
                    setIsLoading(false);
                    setSelectedPredictions([]);
                });
        } else {
            setIsLoading(false);
            setPredictions([]);
            notify('Please select the object.', 'standby');
        }
    };

    useEffect(() => {
        if(totalEntries != 0) {
            handleFormSubmit({
                preventDefault: () => true
            })
        }
    }, [pageInfo])

    const handlePredictionSelection = (predictionId) => {
        const index = selectedPredictions.indexOf(predictionId);
        if (index === -1) {
            setSelectedPredictions(prevSelected => [...prevSelected, predictionId]);
        } else {
            setSelectedPredictions(prevSelected => prevSelected.filter(id => id !== predictionId));
        }
    };

    const handleObjectSelection = (event) => {
        const selectedValues = event.target.value;
        setSelectedObjectIds(Array.isArray(selectedValues) ? selectedValues : [selectedValues]);
    };

    const handleStationSelection = (event) => {
        const selectedValues = event.target.value;
        setSelectedStationIds(Array.isArray(selectedValues) ? selectedValues : [selectedValues]);
    };

    const handleBookSelectedPredictions = async () => {
        for (const index of selectedPredictions) {
            const prediction = predictions[index];
            if (!prediction) continue;
            try {
                await createBooking({ prediction_id: prediction.prediction_id });
            } catch (error) {
                const errorMessage = error.message || `Error saving booking for prediction - norad: ${prediction.object.norad_id}, AOS: ${moment(prediction.time_aos).utc().format("HH:mm:ss")}`;
                notify(errorMessage, 'critical');
            }
        }

        console.log('All bookings saved.');
        setPredictions([]);
        setSelectedPredictions([]);
        handleTimelineFormSubmit();

        setPageInfo(oldPageInfo => ({...oldPageInfo, page: 0}))
        setTotalEntries(0)
    };

    const handleTimeOptionChange = (event) => {
        setTimeOption(event.target.value);
    };

    return (
        <div>
            <div>
                <RuxAccordionItem>
                    <div slot="label">Calculate</div>
                    <form style={{ display: 'flex', alignItems: 'center', gap: '1rem' }} onSubmit={handleFormSubmit}>
                        <RuxRadioGroup
                            name="radios"
                            label="Select time option"
                            value={timeOption}
                            onRuxchange={handleTimeOptionChange}
                        >
                            <RuxRadio value="3h">Next 3 hours</RuxRadio>
                            <RuxRadio value="12h">Next 12 hours</RuxRadio>
                            <RuxRadio value="24h">Next 24 hours</RuxRadio>
                        </RuxRadioGroup>
                        <RuxSelect
                            style={{ width: '40%' }}
                            id="objectSelection"
                            label="Select objects"
                            value={selectedObjectIds}
                            onRuxchange={handleObjectSelection}
                            multiple
                        >
                            {objects.map((object) => (
                                <RuxOption
                                    key={String(object.norad_id)}
                                    value={String(object.norad_id)}
                                    label={object.norad_id + ' ' + object.name}
                                />
                            ))}
                        </RuxSelect>
                        <RuxSelect
                            style={{ width: '40%' }}
                            id="stationSelection"
                            label="Select stations"
                            value={selectedStationIds}
                            onRuxchange={handleStationSelection}
                            multiple
                        >
                            {stations.map(station => (
                                <RuxOption
                                    key={station.station_id}
                                    value={station.station_id}
                                    label={station.station_name}
                                />
                            ))}
                        </RuxSelect>
                        <RuxButton style={{ marginBottom: '-130px' }} type="button" onClick={handleFormSubmit}>Calculate</RuxButton>
                    </form>
                </RuxAccordionItem>
                <br />
            </div>
            <div>
                <RuxTable>
                    <RuxTableBody>
                        <RuxTableHeaderRow selected="false">
                            <RuxTableHeaderCell></RuxTableHeaderCell>
                            <RuxTableHeaderCell>Station</RuxTableHeaderCell>
                            <RuxTableHeaderCell>Object</RuxTableHeaderCell>
                            <RuxTableHeaderCell>Duration</RuxTableHeaderCell>
                            <RuxTableHeaderCell>AOS</RuxTableHeaderCell>
                        </RuxTableHeaderRow>
                        {isLoading && (
                            <RuxTableRow>
                                <td colSpan={7} style={{ textAlign: 'center' }}>
                                    <RuxIndeterminateProgress></RuxIndeterminateProgress>
                                </td>
                            </RuxTableRow>
                        )}
                        {!isLoading &&
                            predictions?.map((p, i) => (
                                <RuxTableRow
                                    selected={selectedPredictions.includes(i).toString()}
                                    key={i}
                                >
                                    <RuxTableCell>
                                        {p.conflicting ? (
                                            p.booking_id === null ? (
                                                <RuxCheckbox
                                                    checked={selectedPredictions.includes(i)}
                                                    onRuxchange={() => handlePredictionSelection(i)}
                                                    disabled=""
                                                />
                                            ) : (
                                                <Link to={`/bookings/${p.booking_id}`}>
                                                    <rux-button style={{ marginLeft: '-6px' }} secondary icon-only icon="launch" borderless />
                                                </Link>
                                            )
                                        ) : (
                                            <RuxCheckbox
                                                checked={selectedPredictions.includes(i)}
                                                onRuxchange={() => handlePredictionSelection(i)}
                                            />
                                        )}
                                    </RuxTableCell>
                                    <RuxTableCell>{p.station.station_name}</RuxTableCell>
                                    <RuxTableCell>{p.object.name}
                                        <br />
                                        <span style={{
                                            fontSize: '0.8em',
                                            color: "gray"
                                        }}>{p.object.norad_id || 'n/a'}</span>
                                    </RuxTableCell>
                                    <RuxTableCell>{duration(p.time_aos, p.time_los)}
                                        <br />
                                        <span style={{ fontSize: '0.8em', color: "gray" }}>{p.maximum
                                            ? "max. [" + parseFloat(p.maximum.azimuth).toFixed(0) + "," + parseFloat(p.maximum.elevation).toFixed(0) + "]"
                                            : 'n/a'}</span>
                                    </RuxTableCell>
                                    <RuxTableCell>
                                        {timeToNow(p.time_aos)}<br />
                                        <span style={{ fontSize: '0.75em' }}>
                                            {moment(p.time_aos).utc().format("HH:mm:ss")}
                                        </span>
                                    </RuxTableCell>
                                </RuxTableRow>
                            ))}
                    </RuxTableBody>
                </RuxTable>
            </div>
            <br />
            <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                <RuxButtonGroup h-align="left" style={{display: 'inline-block'}}>
                    <RuxButton onClick={handleBookSelectedPredictions}
                        disabled={selectedPredictions.length === 0}>
                        Book selected passes
                    </RuxButton>
                </RuxButtonGroup>
                <Pagination pageInfo={pageInfo} setPageInfo={setPageInfo} totalEntries={totalEntries}/>
            </div>
        </div>
    );
};

export default FuturePassesTable;