import React, { useState, useEffect, useMemo } from 'react';
import WorldMap from './WorldMap';
import * as objectApi from '../../apis/objectApi';
import { getAccountStations } from '../../apis/accountApi';
import ControlPanel from './ControlPanel';
import { useAuth } from '../../auth/AuthContext';

const WorldMapMonitoring = () => {
  const [selectedObjects, setSelectedObjects] = useState([]);
  const [selectedStations, setSelectedStations] = useState([]);
  const [stations, setStations] = useState([]);
  const [objects, setObjects] = useState([]);
  const [objectPathStates, setObjectPathStates] = useState({});
  const [isControlPanelVisible, setIsControlPanelVisible] = useState(false);
  const { user } = useAuth()

  useEffect(() => {
    const loadStations = async () => {
      try {
        const stations = await getAccountStations(user) || [];
        setStations(stations);
  
        const initialSelectedStations = stations.map((station) => String(station.station_id));
        setSelectedStations(initialSelectedStations);
      } catch (error) {
        console.error('Error loading stations:', error);
      }
    }
  
      const loadObjects = async () => {
      try {
        const objects = await objectApi.getObjects() || [];
        setObjects(objects);
  
        const initialSelectedObjects = objects.map((object) => String(object.norad_id));
        setSelectedObjects(initialSelectedObjects);
      } catch (error) {
        console.error('Error loading objects:', error);
      }
    };
  
    loadStations();
    loadObjects()
  }, []);

  const handleObjectSelection = (selectedObject, isChecked) => {
    setSelectedObjects((prevSelectedObjects) => {
      return isChecked
        ? [...prevSelectedObjects, selectedObject]
        : prevSelectedObjects.filter((obj) => obj !== selectedObject);
    });
  };

  const handleObjectPathChange = (event, noradId) => {
    console.log('Switch Toggled for Object with noradId:', noradId);

    setObjectPathStates((prevObjectPathStates) => ({
      ...prevObjectPathStates,
      [noradId]: event.target.checked,
    }));

    console.log('Object Path States:', objectPathStates);
  };

  const handleStationSelection = (selectedStation, isStationChecked) => {
    setSelectedStations((prevSelectedStations) => {
      return isStationChecked
        ? [...prevSelectedStations, selectedStation]
        : prevSelectedStations.filter((station) => station !== selectedStation);
    });
  };

  const onToggleControlPanel = () => {
    setIsControlPanelVisible((prev) => !prev);
  };
  
  return (
    <div style={{ display: 'flex', justifyContent: 'space-around', marginTop: '7px' }}>
    <div style={{ width: isControlPanelVisible ? '23%' : '0%', transition: 'width 0.3s ease' }}>
        <div>
          <ControlPanel 
           selectedObjects={selectedObjects}
           selectedStations={selectedStations}
           onObjectSelection={handleObjectSelection}
           handleStationSelection={handleStationSelection}
           handleObjectPathChange={handleObjectPathChange}
           objectPathStates={objectPathStates}
           onToggleControlPanel={onToggleControlPanel}
           isControlPanelVisible={isControlPanelVisible}
           />
        </div>
      </div>

      <div style={{ width: isControlPanelVisible ? '76%' : '100%', transition: 'width 0.3s ease' }}>
        <WorldMap
          objects={objects}
          stations={stations}
          selectedObjects={selectedObjects}
          selectedStations={selectedStations}
          handleObjectPathChange={handleObjectPathChange}
          objectPathStates={objectPathStates}
        />
      </div>
    </div>
  );
};

export default WorldMapMonitoring;