import React, { useEffect, useState } from 'react';
import { RuxCheckbox, RuxCard, RuxTable, RuxTableBody, RuxTableHeaderRow, RuxTableHeaderCell, RuxTableRow, RuxTableCell } from '@astrouxds/react';
import * as objectApi from '../../apis/objectApi';
import { getAccountStations } from '../../apis/accountApi';
import { useAuth } from '../../auth/AuthContext';

function ControlPanel({ onObjectSelection, handleStationSelection, handleObjectPathChange, objectPathStates, onToggleControlPanel, isControlPanelVisible }) {
  const [objects, setObjects] = useState([]);
  const [stations, setStations] = useState([]);
  const [checkboxStates, setCheckboxStates] = useState({});
  const [checkboxStationStates, setCheckboxStationStates] = useState({});

  const { user } = useAuth()

  useEffect(() => {
    const loadObjects = async () => {
      try {
        const objects = await objectApi.getObjects() || [];
        objects.sort((a, b) => a.name.localeCompare(b.name));
        setObjects(objects);

        const initialCheckboxStates = {};
        objects.forEach((object) => {
          initialCheckboxStates[object.norad_id] = true;
        });
        setCheckboxStates(initialCheckboxStates);
      } catch (error) {
        console.error('Error loading objects:', error);
      }
    }

    loadObjects();
  }, []);

  useEffect(() => {
    const loadStations = async () => {
      try {
        const stations = await getAccountStations(user) || [];
        stations.sort((a, b) => a.station_name.localeCompare(b.station_name));
        setStations(stations);

        const initialCheckboxStationStates = {};
        stations.forEach((station) => {
          initialCheckboxStationStates[station.station_id] = true;
        });
        setCheckboxStationStates(initialCheckboxStationStates);
      } catch (error) {
        console.error('Error loading stations:', error);
      }
    };

    loadStations();
  }, []);

  const handleCheckboxChange = (event) => {
    const norad_id = event.target.value;
    const isChecked = event.target.checked;

    setCheckboxStates((prevCheckboxStates) => ({
      ...prevCheckboxStates,
      [norad_id]: isChecked,
    }));

    onObjectSelection(norad_id, isChecked);

    if (!isChecked) {
      handleObjectPathChange({ target: { value: norad_id, checked: false } }, norad_id);
    }
  };

  const handleCheckboxStationChange = (event) => {
    const station_id = event.target.value;
    const isStationChecked = event.target.checked;

    setCheckboxStationStates((prevCheckboxStationStates) => ({
      ...prevCheckboxStationStates,
      [station_id]: isStationChecked,
    }));

    handleStationSelection(station_id, isStationChecked);
  };

  return (
    <div style={{ display: 'grid', flexDirection: 'column', zIndex: 1 }}>

      <div style={{ zIndex: 1, width: '180px', margin: isControlPanelVisible ? '0px' : '9%' }} onClick={onToggleControlPanel}>
        <a style={{ textDecoration: 'none', cursor: 'pointer' }}>{!isControlPanelVisible && '> Show Control Panel'}</a>
      </div>

      {isControlPanelVisible && (
        <div style={{ display: 'grid', flexDirection: 'column', zIndex: 1 }}>
          <RuxCard style={{ zIndex: 2 }}>
            <div slot="header" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <span>Control Panel</span>
              <a onClick={onToggleControlPanel} style={{ textDecoration: 'none', cursor: 'pointer', fontSize: "16px" }}>{isControlPanelVisible && '> Hide Control Panel'} </a>
            </div>

            <RuxTable>
              <RuxTableBody>
                <RuxTableHeaderRow>
                  <RuxTableHeaderCell>Object</RuxTableHeaderCell>
                  <RuxTableHeaderCell>Visible</RuxTableHeaderCell>
                  <RuxTableHeaderCell>Ground Track</RuxTableHeaderCell>
                </RuxTableHeaderRow>
                {objects && objects.length > 0 ? (
                  objects.map((object) => (
                    <RuxTableRow key={object.object_id}>
                      <RuxTableCell>{object.name}
                        <br />
                        <span style={{
                          fontSize: '0.8em',
                          color: "gray"
                        }}>{object.norad_id || 'n/a'}</span>
                      </RuxTableCell>
                      <RuxTableCell>
                        <RuxCheckbox
                          value={object.norad_id}
                          checked={checkboxStates[object.norad_id] || false}
                          onRuxchange={(event) => handleCheckboxChange(event, 'objects')}
                        />
                      </RuxTableCell>
                      <RuxTableCell>
                        <RuxCheckbox
                          style={{ marginLeft: '10px' }}
                          checked={objectPathStates[object.norad_id] || false}
                          onRuxchange={(event) => handleObjectPathChange(event, object.norad_id)}
                          disabled={!checkboxStates[object.norad_id]}
                        ></RuxCheckbox>
                      </RuxTableCell>
                    </RuxTableRow>
                  ))
                ) : (
                  <div>No objects available</div>
                )}
              </RuxTableBody>
            </RuxTable>

            <hr></hr>

            <RuxTable>
              <RuxTableBody>
                <RuxTableHeaderRow>
                  <RuxTableHeaderCell>Station</RuxTableHeaderCell>
                  <RuxTableHeaderCell>Visible</RuxTableHeaderCell>
                </RuxTableHeaderRow>
                {stations && stations.length > 0 ? (
                  stations.map((station) => (
                    <RuxTableRow key={station.station_id}>

                      <RuxTableCell>
                        {station.station_name}
                      </RuxTableCell>

                      <RuxTableCell>
                        <RuxCheckbox
                          value={station.station_id}
                          checked={checkboxStationStates[station.station_id] || false}
                          onRuxchange={(event) => handleCheckboxStationChange(event, 'stations')}
                        />
                      </RuxTableCell>

                    </RuxTableRow>
                  ))
                ) : (
                  <div>No stations available</div>
                )}
              </RuxTableBody>
            </RuxTable>

          </RuxCard>
        </div>
      )}
    </div>
  );
}

export default ControlPanel;