import api from './config/axiosConfig';

const ACCOUNT_API_BASE_URL = 'auth/accounts';

export const createAccount = (data) => {
    return api.post(ACCOUNT_API_BASE_URL, data);
};

export const getAccounts = () => {
    return api.get(ACCOUNT_API_BASE_URL);
};
export const getAccountStations = async (user) => {
    const accounts = await getAccounts()
    return accounts.find(account => account.account_id === user.account_id).stations
}

export const getObjectsByAccountId = (accountId) => {
    const url = `${ACCOUNT_API_BASE_URL}/${accountId}`;
    return api.get(url);
  };

export const updateAccount = (accountId, data) => {
    const url = `${ACCOUNT_API_BASE_URL}/${accountId}`;
    return api.put(url, data);
};

export const deleteAccount = (accountId) => {
    const url = `${ACCOUNT_API_BASE_URL}/${accountId}`;
    return api.delete(url);
};